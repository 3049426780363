<template>
  <ThemeProvider>
    <Main></Main>
    <ToastHandler />
  </ThemeProvider>
</template>

<script>
import ThemeProvider from './components/bundles/ThemeProvider.vue';
import ToastHandler from './components/toastHandler/ToastHandler.vue';

export default {
  name: "App",
  components: { ThemeProvider, ToastHandler }
};
</script>

<style>
.p-column-filter-menu {
  margin-left: 10px !important;
}

.view-details {
  width: 500px !important;
}

/* Utils */
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

/* Form Wizard */
.vue-form-wizard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 40px - 1.75rem);
  /*height: 100%;*/
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.vue-form-wizard .wizard-navigation {
  display: flex;
  flex-direction: column;
  height: calc(100% - 45px);
  /*height: 100%;*/
}

.vue-form-wizard .wizard-tab-content {
  display: flex;
  flex-direction: column;
  /*height: calc(100% - 78px);*/
  height: 100%;
  padding: 15px 0px 0px !important;
}

.wizard-tab-content .wizard-tab-container {
  display: flex;
  align-items: flex-start;
  height: 100%;
  padding: 0px !important;
  overflow: auto;
  overflow-x: hidden;
}

.wizard-tab-content .wizard-tab-container .content-fluid {
  height: 100%;
  padding: 10px 15px;
}

.wizard-tab-container::-webkit-scrollbar,
.p-dropdown-items-wrapper::-webkit-scrollbar,
.wrap-channels.list-box-contents::-webkit-scrollbar,
.p-datatable-scrollable>.p-datatable-wrapper::-webkit-scrollbar {
  width: var(--guo-scrollbar-width, 8px);
  height: var(--guo-scrollbar-height, 8px);
  border-radius: var(--guo-scrollbar-border-radius, 15px);
  background-color: var(--guo-scrollbar-bg);
}

.wizard-tab-container::-webkit-scrollbar-thumb,
.p-dropdown-items-wrapper::-webkit-scrollbar-thumb,
.wrap-channels.list-box-contents::-webkit-scrollbar-thumb,
.p-datatable-scrollable>.p-datatable-wrapper::-webkit-scrollbar-thumb {
  border-radius: var(--guo-scrollbar-thumb-border-radius);
  background-color: var(--guo-scrollbar-thumb-bg);
}

.wizard-tab-container::-webkit-scrollbar-corner,
.p-dropdown-items-wrapper::-webkit-scrollbar-corner,
.wrap-channels.list-box-contents::-webkit-scrollbar-corner,
.p-datatable-scrollable>.p-datatable-wrapper::-webkit-scrollbar-corner {
  background: var(--bs-body-bg);
}

.wrap-channels .p-carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wizard-tab-content .card-title {
  margin: 0px;
}

.vue-form-wizard .wizard-nav>li {
  display: flex;
}

.wizard-progress-with-circle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bs-gray-300);
}

.vue-form-wizard .wizard-icon-circle {
  border-color: var(--bs-gray-300);
  background-color: var(--bs-body-bg) !important;
}

.vue-form-wizard .wizard-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  float: none;
}

.vue-form-wizard .wizard-nav li:first-child::before,
.vue-form-wizard .wizard-nav li:last-child::before {
  content: "";
  position: absolute;
  top: 0;
  width: 50%;
  height: 50px;
  background-color: var(--bs-body-bg);
}

.vue-form-wizard .wizard-nav li:first-child::before {
  left: 0;
}

.vue-form-wizard .wizard-nav li:last-child::before {
  right: 0;
}

.vue-form-wizard .wizard-nav-pills>li>a {
  color: var(--bs-step-wizard-color) !important;
}

.wizard-tab-container .card-body,
.wizard-tab-container .p-datatable {
  height: 100%;
}

.vue-form-wizard .wizard-card-footer {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 1rem !important;
  margin: 0;
  padding: 10px 0 0 !important;
  background-color: transparent !important;
}

.vue-form-wizard .clearfix:after {
  content: unset !important;
}

.vue-form-wizard .wizard-btn,
.vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn {
  border-width: 1px !important;
  border-radius: var(--bs-border-radius-pill) !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  font-weight: 400 !important;
  line-height: 1.5;
  font-size: 1rem;
  min-width: 40px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.vue-form-wizard .wizard-btn,
.vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: var(--bs-body-bg) !important;
}

.vue-form-wizard .wizard-btn:hover,
.vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn:hover {
  background-color: var(--guo-color-primary-hover);
  border-color: var(--guo-color-primary-hover);
}

.vue-form-wizard .wizard-footer-left .wizard-btn {
  color: var(--guo-color-primary) !important;
  background-color: var(--wizard-footer-left-btn-bg) !important;
  border-color: var(--bs-primary);
}

.vue-form-wizard .wizard-footer-left .wizard-btn:hover {
  color: #FFF !important;
  background-color: var(--guo-color-primary-hover) !important;
  border-color: var(--guo-color-primary-hover) !important;
}

.vue-form-wizard .wizard-nav-pills>li.active>a .wizard-icon,
.vue-form-wizard .wizard-nav-pills>li.active>a:hover .wizard-icon,
.vue-form-wizard .wizard-nav-pills>li.active>a:focus .wizard-icon {
  color: var(--bs-validated-btn-text-color) !important;
}

.nav-group {
  padding: 0.10rem;
  border-radius: var(--bs-border-radius);
  background-color: var(--bs-gray-100);
}

.nav-group.nav-group-outline {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: transparent;
  border: 1px solid var(--bs-border-color);
}

.nav-group .btn-nav {
  color: var(--guo-color-gray);
}

.nav-group .btn-check+.btn:hover {
  color: var(--guo-color-primary);
}

.nav-group .btn-check:checked+.btn,
.nav-group .btn.active,
.nav-group .btn.show,
.nav-group .btn:first-child:active {
  color: var(--guo-color-primary);
  background-color: rgba(var(--guo-color-primary-rgb), 5%) !important;
  border-color: transparent !important;
}

/* Box Channels / Contents */
.content-item {
  position: relative;
  width: 100%;
  padding: 0;
}

.nav.nav-pills.nav-pills-custom .show>.nav-link,
.nav.nav-pills.nav-pills-custom .nav-link {
  position: relative;
  width: 120px;
  min-height: 100px;
  background-color: transparent;
  border: 1px dashed var(--bs-gray-500);
  border-radius: 0.625rem;
  cursor: pointer;
}

.nav.nav-pills.nav-pills-custom .show>.nav-link:hover,
.nav.nav-pills.nav-pills-custom .nav-link:hover {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary-light);
}

.nav.nav-pills.nav-pills-custom .show>.nav-link.active,
.nav.nav-pills.nav-pills-custom .nav-link.active {
  border: 1px solid transparent;
  background-color: var(--bs-primary-light);
}

.nav.nav-pills.nav-pills-custom .nav-link .nav-icon img,
.content-item .box-item .box-img img {
  width: 100%;
}

.nav.nav-pills.nav-pills-custom .nav-link:hover .nav-text {
  color: var(--bs-primary);
}

.nav.nav-pills.nav-pills-custom .nav-link .nav-text,
.content-item .box-item .box-text {
  margin-top: 1rem;
  text-align: center;
  color: var(--bs-body-text);
  transition: color 0.2s ease;
}

.nav.nav-pills.nav-pills-custom .show>.nav-link.active .nav-text,
.nav.nav-pills.nav-pills-custom .nav-link.active .nav-text {
  color: var(--guo-color-primary) !important;
}

.p-buttonset .p-button {
  color: var(--wizard-footer-left-btn-color);
  padding: 0.4rem 2.25rem !important;
  background-color: rgba(var(--guo-color-primary-rgb), 5%) !important;
  border-color: transparent !important;
  transition: all 0.2s ease-in-out !important;
}

.p-buttonset .p-button:hover {
  color: var(--guo-color-primary) !important;
  background-color: rgba(var(--guo-color-primary-rgb), 8%) !important;
}

.p-buttonset .p-button:first-of-type,
.p-buttonset .p-button:last-of-type {
  border-radius: var(--bs-border-radius-pill) !important;
}

.p-button-label {
  font-weight: 500;
}

.p-selectbutton .p-button.p-highlight,
.p-selectbutton .p-button.p-highlight:hover {
  color: var(--guo-color-primary);
  background-color: rgba(var(--guo-color-primary-rgb), 20%) !important;
}

.p-button:focus {
  box-shadow: 0 0 0 0.18rem rgba(var(--guo-color-primary-rgb), 45%);
}

.p-grid.grid {
  display: flex !important;
  flex-direction: column !important;
  gap: 0.65rem !important;
}

.caption-text {
  font-size: 12px;
}

.p-tag-icon,
.p-tag-value,
.p-tag-icon.pi {
  line-height: 1;
}

.p-tag.tag-date,
.p-tag.tag-status {
  font-weight: 500;
  padding: 0.15rem 0.65rem;
  border-radius: 4px;
}

.p-tag.tag-date {
  background-color: #f3f5f7;
  color: var(--guo-color-dark);
}

.p-inputswitch.inputswitch-sm {
  transform: scale(0.75);
}

.tag-verifying {
  color: var(--cyan-900) !important;
  background-color: var(--cyan-200) !important;
}

.tag-duplicate {
  color: var(--teal-900) !important;
  background-color: var(--teal-200) !important;
}

.tag-suspended {
  color: var(--red-600) !important;
  background-color: var(--red-100) !important;
}

.tag-pending {
  color: var(--blue-800) !important;
  background-color: var(--blue-200) !important;
}

.tag-processing {
  color: var(--yellow-800) !important;
  background-color: var(--yellow-200) !important;
}

.tag-verified {
  color: var(--green-800) !important;
  background-color: var(--green-200) !important;
}

/* Vista Preview */
.wrap-preview .p-dataview {
  height: 100%;
}

.wrap-preview .p-dataview-content {
  min-height: 526px;
}

.wrap-preview.pending-review .p-dataview-content {
  min-height: 480px;
}

.wrap-preview .p-dataview-header {
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.wrap-preview .sort-preview.p-dropdown {
  width: 130px;
}

.wrap-preview .p-dropdown-label {
  display: flex;
  align-items: center !important;
  height: 42px;
}

.wrap-preview .sort-preview.p-dropdown .p-dropdown-trigger {
  width: 2.5rem;
}

.wrap-preview .preview {
  width: 500px;
  height: 370px;
  background-color: #f3f5f7;
  overflow: hidden;
}

.wrap-preview.pending-review .preview {
  width: 100%;
  height: 100%;
}

.wrap-preview.pending-review .tabview-custom .p-tabview-panels {
  height: calc(100% - 42px);
}

.wrap-preview.pending-review .tabview-custom .p-tabview-panels .p-tabview-panel {
  height: 100%;
}

.wrap-preview .wrap-img {
  width: auto;
  height: 340px;
}

.wrap-preview .wrap-img img {
  width: 100%;
  filter: grayscale(1);
  opacity: 0.3;
}

.wrap-preview .p-dropdown-panel {
  top: 44px !important;
  left: unset !important;
  right: 0;
}

.p-inputtext:not(.p-invalid):not(.p-dropdown-label),
.p-dropdown {
  background: var(--bs-app-blank-bg) !important;
  border: 1px solid var(--bs-border-color) !important;
}

.box-metas {
  font-size: 13px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease-in-out;
}

.box-metas::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 4px;
  background-color: var(--bs-gray-100);
}

.box-metas::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--bs-gray-300);
}

.box-metas:hover::-webkit-scrollbar {
  background-color: var(--bs-gray-200);
}

.box-metas:hover::-webkit-scrollbar-thumb {
  background-color: var(--bs-gray-500);
}

.item-box-metas {
  width: 240px;
  height: 210px;
}

.item-box-dim {
  height: 120px;
}

.item-box-word {
  height: 115px;
}

.switch-theme {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 55px;
  height: 45px;
}

.switch-theme .p-inputswitch {
  width: 3.25rem;
  height: 1.9rem;
}

.switch-theme .p-inputswitch .p-inputswitch-slider {
  background-color: var(--bs-body-bg) !important;
}

.switch-theme .p-inputswitch .p-inputswitch-slider::before {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  top: 0;
  bottom: 0;
  margin: auto;
  font-family: bootstrap-icons;
  font-size: 20px;
  background-color: transparent;
}

.switch-theme .p-inputswitch-slider.theme-light::before {
  content: "\F5A1";
}

.switch-theme .p-inputswitch-slider.theme-dark::before {
  content: "\F495";
  left: 1px;
}

.switch-theme .p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.2) !important;
}

.switch-theme .p-inputswitch:hover .p-inputswitch-slider::before {
  color: var(--guo-color-primary);
}

.p-inputswitch .p-inputswitch-slider {
  background: #bcc6d3;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #9faab9;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--guo-color-primary);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--guo-color-primary-hover);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem rgba(var(--guo-color-primary-rgb), 0.2);
}

.wrap-items.disabled .item-card .p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem rgb(185 195 209 / 50%);
}

.active.stretched-link::after {
  border: 2px solid var(--guo-color-primary) !important;
  border-radius: 0.625rem;
}

.p-tooltip .p-tooltip-text {
  background: var(--guo-color-dark2);
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: var(--guo-color-dark2);
}

.fis.flag-size-24px {
  font-size: 1.65rem;
  border-radius: 50%;
}

/* Tables */
.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
  vertical-align: middle;
  padding: 0.1rem 0.65rem;
  height: 36px;
}

.p-paginator .p-dropdown {
  height: 2.5rem;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-current {
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2.5rem;
  height: 2.5rem;
}

.wrap-list-clients nav.p-paginator-bottom {
  position: absolute;
  width: 70%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -4px;
}

.p-datatable .p-datatable-header {
  border-width: 0 0 1px 0 !important;
  background-color: transparent !important;
  padding: 0 0 10px !important;
}

.p-datatable-scrollable .p-frozen-column {
  background-color: var(--bs-table-frozen-column-bg) !important;
  z-index: 1;
}

.p-datatable-scrollable-table>.p-datatable-thead {
  box-shadow: 0 0 1px #7a828d;
  z-index: 2 !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view-details .p-sidebar-header {
  justify-content: space-between !important;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--guo-color-primary-rgb), 0.15);
}

.p-sidebar .p-sidebar-header {
  padding: 10px 1.25rem;
}

.view-details .p-sidebar-content,
.view-details .p-tabview-panels {
  padding: 0;
}

.view-details .p-tabview-nav-container {
  padding: 0 1.25rem;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 5px 20px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: var(--bs-body-title);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: var(--guo-color-primary);
  border-color: var(--guo-color-primary);
  background: var(--bs-body-bg) !important;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: var(--bs-body-bg) !important;
  border-color: var(--guo-color-primary) !important;
  color: var(--bs-body-title) !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: inset 0 0 0 0.2rem rgba(var(--guo-color-primary-rgb), 0.15);
}

.p-tabview .thumb-tab {
  width: 30px;
  height: 30px;
}

td span.flag-size-24px {
  width: 24px !important;
  height: 24px !important;
}

.wrap-filters {
  display: flex;
  align-items: center;
  width: calc(100% - 375px);
  overflow: auto;
  overflow-y: hidden;
}

.wrap-filters .chips {
  width: 100%;
  height: 26px;
}

.wrap-filters .p-chip {
  font-size: 12px;
}

.wrap-filters .p-chip .p-chip-text {
  line-height: 1.2;
}

.wrap-label-text {
  display: flex !important;
  align-items: center !important;
  gap: 6px;
  width: 275px;
  height: 35px;
}

.label-bg-light {
  width: 275px;
  padding: 0.35em 0.65em !important;
  color: var(--bs-body-color) !important;
  background-color: var(--label-bg-light) !important;
  line-height: 1;
  border-radius: 0.375rem;
}

.label-bg-light:hover {
  color: var(--guo-color-primary) !important;
  background-color: var(--label-bg-light-hover) !important;
}

.p-inplace-content.wrap-input {
  position: relative;
  display: flex !important;
  align-items: center;
  width: 100%;
}

.wrap-input .p-inputtext,
.wrap-input .p-button {
  height: 35px;
}

.wrap-input .p-inputtext {
  width: 100%;
  padding-right: 38px;
}

.wrap-input .p-inputtext:enabled:hover {
  border-color: var(--guo-color-primary) !important;
}

.wrap-label-text .p-inplace-display:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--guo-color-primary-rgb), 0.2);
}

.wrap-input .p-inputtext:enabled:enabled:focus {
  border-color: var(--guo-color-primary) !important;
  box-shadow: 0 0 0 0.2rem rgba(var(--guo-color-primary-rgb), 0.2);
}

.wrap-input .p-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  border-radius: 0 6px 6px 0;
  color: var(--bs-input-button-color);
  background-color: var(--bs-input-button-bg) !important;
  border-color: var(--bs-input-button-bg) !important;
}

.wrap-input .p-button:hover {
  background-color: var(--bs-input-button-bg-hover) !important;
  border-color: var(--bs-input-button-bg-hover) !important;
}

.status-bg-default {
  color: var(--bs-body-color);
  background-color: var(--bs-secondary-bg);
}

.status-bg-success {
  color: var(--bs-success);
  background-color: var(--bs-success-bg-subtle);
}

.status-bg-danger {
  color: var(--bs-danger);
  background-color: var(--bs-danger-bg-subtle);
}

.p-button.btn-lang .p-button-label {
  position: absolute;
  top: 1px;
  right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 15px;
  color: var(--bs-button-text-color) !important;
  background-color: var(--bs-lang-button-label-bg);
  font-size: 13px !important;
  padding: 0px 2px 2px;
  border-radius: 3px !important;
  text-transform: uppercase;
}
</style>
