<template>
  <Dialog v-model:visible="store.searchDialogOpen" modal header="Buscar episodio" position="top"
    :style="{ width: '50vw' }" @hide="onHide">
    <div v-focustrap>
      <div class="mb-8">
        <IconField iconPosition="left">
          <InputIcon class="pi pi-search"> </InputIcon>
          <InputText class="w-100" v-model="searchKey" placeholder="Buscar episodio" autofocus />
        </IconField>
      </div>
      <div class="my-4">
        <template v-if="loading">
          <div>
            <p><i class="pi pi-spin pi-spinner me-2" style="vertical-align: middle;"></i> buscando...</p>
          </div>
        </template>
        <template v-if="episodes.length && !loading">
          <DataView :value="episodes">
            <template #list="slotProps">
              <div class="py-2 mb-4" v-for="(episode, index) in slotProps.items" :key="index">
                <div class="d-flex justify-content-between align-items-end">
                  <div class="d-flex flex-column">
                    <h6>{{ episode.episodeName || 'Sin nombre' }}
                      <Tag :style="{ background: episode.isPublished ? 'var(primary-500)' : 'gray' }"
                        :value="episode.isPublished ? 'Published' : 'Draft'" />
                    </h6>
                    <p class="m-0">{{ episode.episodeId }}</p>
                  </div>
                  <Button severity="success" label="cargar" size="small" @click="handleClick(episode)"></Button>
                </div>
              </div>
            </template>
          </DataView>
        </template>
        <template v-if="!loading && !episodes.length && searchKey">
          <div>
            <p>0 resultados encontrados</p>
          </div>
        </template>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useGlobalStore } from '@/stores';
import { debounce } from '@/helpers/helpers';
import services from '@/helpers/services';

const store = useGlobalStore()
const episodes = ref([]);
const loading = ref(false);
const searchKey = ref("")

const debouncedFetch = debounce(() => {
  episodes.value = [services.getEpisode()]
  loading.value = false
}, 1000);

watch(searchKey, (...args) => {
  if (!searchKey.value) {
    return;
  }
  loading.value = true
  debouncedFetch(...args);
})

const handleClick = (episode) => {
  store.loadEpisode(episode)
  store.toggleSearchDialog(false)
}

const onHide = () => {
  searchKey.value = ''
  episodes.value = ''
}
</script>