import { FilterMatchMode, FilterOperator } from "primevue/api"

export const clientInitialFilters = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  companyId: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  name: {
    operator: FilterOperator.AND,
    constraints: [
      { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ],
  },
  'partner.partnerGroup.name': {
    operator: FilterOperator.AND,
    constraints: [
      { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ],
  },
  'partner.name': {
    operator: FilterOperator.AND,
    constraints: [
      { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ],
  },
  'typology.name': {
    operator: FilterOperator.AND,
    constraints: [
      { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ],
  },
  'location.ubication.country.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
  'location.ubication.locality': {
    operator: FilterOperator.AND,
    constraints: [
      { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ],
  },
  'location.ubication.city': {
    operator: FilterOperator.AND,
    constraints: [
      { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ],
  },
  'location.ubication.region': {
    operator: FilterOperator.AND,
    constraints: [
      { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ],
  },
  /* 'gbp.state': { value: null, matchMode: FilterMatchMode.CONTAINS },
  'activated': { value: null, matchMode: FilterMatchMode.CONTAINS } */
}