<template>
  <div class="card-body p-0">
    <div class="wrap-list-clients d-flex flex-column">
      <TabHeader title="¿A quién asignas el contenido?" />
      <div class="wrap-table">
        <DataTable tableStyle="min-width: 50rem" class="p-datatable-sm" dataKey="location.locationId"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{first} a {last} de {totalRecords}" scrollable scrollHeight="85%" stripedRows
          removableSort paginator filterDisplay="menu" v-model:filters="filters" :value="store.clientsLoaded" :rows="10"
          :rowsPerPageOptions="[5, 10, 20, 50]" v-model:selection="store.clients" @rowSelect="onRowSelect"
          @rowUnselect="onRowUnselect" @rowSelectAll="onRowSelectAll" @rowUnselectAll="onRowUnselectAll"
          :globalFilterFields="[
            'location.clientId',
            'location.name',
            'partner.partnerGroup.name',
            'partner.name',
            'location.typology.name',
            'location.country',
            'location.locality'
          ]">
          <template #header>
            <div class="d-flex justify-content-between">
              <Button icon="pi pi-filter-slash" label="Borrar filtros" rounded @click="setInitialFilters()" />
              <IconField iconPosition="left">
                <InputIcon class="pi pi-search"> </InputIcon>
                <InputText v-model="filters.global.value" placeholder="Buscar por palabra clave" />
              </IconField>
            </div>
          </template>
          <template #empty> No se encontraron registros </template>
          <template #loading>
            Cargando datos... Espere por favor.
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3rem" class="wrap-select-all" frozen></Column>
          <Column field="location.clientId" header="Identificador" sortable>
            <template #body="{ data }">
              <div class="fw-medium text-center">
                {{ data.location.clientId || '--' }}
              </div>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter"
                placeholder="Buscar por identificador" />
            </template>
          </Column>
          <Column field="location.name" header="Nombre" sortable style="min-width: 200px">
            <template #body="{ data }">
              {{ data.location.name || '--' }}
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter"
                placeholder="Buscar por nombre" />
            </template>
          </Column>
          <Column field="partner.partnerGroup.name" header="Grupo de socio" sortable style="min-width: 200px">
            <template #body="{ data }">
              {{ data.partner?.partnerGroup?.name || '--' }}
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter"
                placeholder="Buscar por grupo de socio" />
            </template>
          </Column>
          <Column field="partner.name" header="Socio" sortable style="min-width: 200px">
            <template #body="{ data }">
              {{ data.partner?.name || '--' }}
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Buscar por socio" />
            </template>
          </Column>
          <Column field="location.typology.name" header="Tipología" sortable style="min-width: 200px">
            <template #body="{ data }">
              {{ data.location?.typology?.name || '--' }}
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter"
                placeholder="Buscar por tipología" />
            </template>
          </Column>
          <Column header="País" filterField="location.ubication.country.name" sortable :showFilterMatchModes="false"
            style="min-width: 200px" bodyClass="text-center">
            <template #filter="{ filterModel }">
              <Dropdown v-model="filterModel.value" :options="countries" optionLabel="name" optionValue="iso2"
                placeholder="Seleccionar país" class="p-column-filter">
                <template #option="slotProps">
                  <div class="d-flex gap-2">
                    <flag :iso="slotProps.option.iso2" class="flag-size-24px" />
                    <span> {{ slotProps.option.name }}</span>
                  </div>
                </template>
              </Dropdown>
            </template>
            <template #body="{ data }">
              <div v-if="data.location?.country" class="d-flex gap-2">
                <flag :iso="data.location?.country.toLowerCase()" class="flag-size-24px" />
                <span>{{ countryNames[data.location?.country.toLowerCase()] }}</span>
              </div>
              <div v-else>--</div>
            </template>
          </Column>
          <Column field="location.locality" header="Localidad" sortable style="min-width: 200px">
            <template #body="{ data }">
              {{ data.location?.locality || "--" }}
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter"
                placeholder="Buscar por localidad" />
            </template>
          </Column>
          <!--  <Column field="location.ubication.city" header="Ciudad" sortable style="min-width: 200px">
            <template #body="{ data }">
              {{ data.location?.ubication?.city || "--" }}
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter"
                placeholder="Buscar por ciudad" />
            </template>
          </Column>
          <Column field="location.ubication.region" header="Región" sortable style="min-width: 200px">
            <template #body="{ data }">
              {{ data.location?.ubication?.region || "--" }}
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter"
                placeholder="Buscar por región" />
            </template>
          </Column> -->
          <!--  <Column header="Estado" filterField="gbp.state" sortable :showFilterMatchModes="false"
            :filterMenuStyle="{ width: '14rem' }" bodyClass="text-center" style="min-width: 125px">
            <template #filter="{ filterModel }">
              <Dropdown v-model="filterModel.value" :options="clientStatuses" optionLabel="status" optionValue="status"
                showClear placeholder="Seleccionar estado" class="p-column-filter">
                <template #option="slotProps">
                  <Tag :value="slotProps.option.status" :class="slotProps.option.color" />
                </template>
              </Dropdown>
            </template>
            <template #body="{ data }">
              <Tag v-if="data.gbp?.state" class="tag-status" :value="data.gbp?.state"
                :class="getSeverity(data.gbp?.state)"></Tag>
              <p v-else>--</p>
            </template>
          </Column> -->
          <!--   <Column field="activated" header="Fecha de alta" sortable bodyClass="text-center" style="min-width: 200px">
            <template #body="{ data }">
              {{ formatDate(data.activated) || '--' }}
            </template>
            <template #filter="{ filterModel }">
              <Calendar id="tb-filter-date" v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"
                mask="99/99/9999" />
            </template>
          </Column> -->
          <!--  <Column field="gbp" header="GBP" bodyClass="text-center" style="min-width: 80px">
            <template #body="{ data }">
              <a v-if="data.gbp?.locationUrl" :href="data.gbp?.locationUrl" target="_blank"><i
                  class="pi pi-window-maximize"></i></a>
              <p v-else>--</p>
            </template>
          </Column> -->
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useGlobalStore } from '@/stores'
import { /* getSeverity, clientStatuses, */ countries, countryNames, /* formatDate */ } from '@/helpers/helpers'
import { clientInitialFilters } from '@/helpers/clientInitialFilters'

const store = useGlobalStore()

const filters = ref(clientInitialFilters)

const setInitialFilters = () => {
  filters.value.global.value = "";
  filters.value = clientInitialFilters
}

const onRowSelect = () => {
  store.syncEpisodeClients()
}

const onRowUnselect = () => {
  store.syncEpisodeClients()
}

const onRowSelectAll = ({ data }) => {
  store.addEpisodeClients(data)
}

const onRowUnselectAll = () => {
  store.removeAllEpisodeClients()
}

store.$onAction(({ name }) => {
  // Cuando se ejecute la accion "restart" del store Global, 
  // reseteamos filtros
  if (name === 'restart') {
    setInitialFilters()
  }
})

</script>