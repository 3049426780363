<template>
  <div class="switch-theme">
    <InputSwitch v-model="themeStore.themeSet" :pt="{
      slider: ({ props }) => ({
        class: props.modelValue ? 'theme-dark' : 'theme-light'
      }),
    }" @input="themeStore.toggleTheme" />
  </div>
</template>

<script setup>
import { useThemeStore } from '@/stores/theme'

const themeStore = useThemeStore()

</script>