<template>
  <div class="card-body p-0">
    <div class="wrap-vue-cal d-flex align-items-center justify-content-center">
      <div class="vue-cal-action">
        <Button label="Programar ejecución" size="small" rounded outlined @click="store.toggleScheduleSidebar(true)" />
      </div>
      <vue-cal ref="calendar" class="vuecal-guo" :time-step="30" v-model:active-view="store.calendarView"
        :disable-views="['years']" today-button :watchRealTime="true" :dblclickToNavigate="false" :cell-contextmenu="true"
        events-count-on-year-view events-count-on-week-view @cell-dblclick="onCellClick($event)"
        :events="store.calendarEvents" :locale="locale" @ready="fetchEvents" @view-change="fetchEvents">
        <template #weekday-heading="{ heading, view }">
          <span class="full">{{ heading.label }}</span>
          <span v-if="view.id === 'week'">&nbsp;{{ heading?.date?.getDate() }}&nbsp;</span>
          <div class="day-event-count" v-if="(eventCount = getDateEventCount(view.events, heading.date)) > 0">
            {{ eventCount }}
          </div>
        </template>
        <template #time-cell="{ hours, minutes }">
          <div :class="{ 'vuecal__time-cell-line': true, hours: !minutes }">
            <strong v-if="!minutes" class="vuecal__time-cell-label" style="font-size: 15px">{{ hours }}</strong>
            <span v-else class="vuecal__time-cell-label" style="font-size: 12px">{{ minutes }}</span>
          </div>
        </template>
      </vue-cal>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useGlobalStore } from '@/stores'
import { calendar, form } from '@/stores/refs'

const { locale } = useI18n();
const store = useGlobalStore();

const onCellClick = () => {
  // ToDo - pasar $event al scheduleSidebar
  store.toggleScheduleSidebar(true)
};

const scrollToCurrentTime = () => {
  // si se esta viendo el tab 4, donde esta el calendario
  if (form.value.activeTabIndex === 4) {
    // hay que esperar a que este completamente renderizado el DOM
    setTimeout(() => {
      const calendarEl = document.querySelector('.vuecal-guo .vuecal__bg')
      const hours = calendar.value.now?.getHours() + calendar.value.now?.getMinutes() / 60
      calendarEl.scrollTo({ top: hours * 70, behavior: 'smooth' })
    }, 1000);
  }
}

const fetchEvents = ({ view, startDate, endDate }) => {
  store.syncCalendarViewDetails({ view, startDate, endDate });
  store.fetchScheduleEvents()
  scrollToCurrentTime()
}

const getDateEventCount = (events, currentDate) => {
  if (!currentDate) return 0;
  // Filtramos la lista de eventos para encontrar aquellos cuyo start es el mismo día que currentDate
  const eventosCoincidentes = events.filter(event => {
    const eventStartDate = event.start;
    return (
      eventStartDate.getFullYear() === currentDate.getFullYear() &&
      eventStartDate.getMonth() === currentDate.getMonth() &&
      eventStartDate.getDate() === currentDate.getDate()
    );
  });
  return eventosCoincidentes.length;
}


</script>

<style lang="scss" scoped>
.vue-cal-action {
  button {
    padding: 15px;
    height: 30px;
    font-size: 0.875rem;
  }
}

.vuecal-guo {
  :deep(.vuecal__event-time) {
    span {
      display: none;
    }
  }
}
</style>