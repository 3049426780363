<template>
  <div class="menu-lang dropdown">
    <Button class="btn btn-icon btn-active-light-primary btn-custom" type="button" icon="bi bi-grid-fill"
      @click="toggleApps" aria-haspopup="true" aria-controls="overlay_apps" aria-label="Seleccionar apps"></Button>
    <Menu ref="menu" id="overlay_Apps" :model="itemsApps" :popup="true">
      <a class="p-menuitem-link" :href="slotProps.item.url">
        <span class="p-menuitem-text">{{ slotProps.item.label }}</span>
      </a>
    </Menu>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { env } from '@/helpers/helpers';

const menu = ref()
const itemsApps = ref()

const toggleApps = (event) => {
  menu.value.toggle(event);
}

onMounted(() => {
  itemsApps.value = [
    {
      label: "Güo ReDa",
      url: env.REDA_URL,
      target: "_blank",
    },/*
    {
      label: "Güo PuE",
      url: env.PUE_URL,
      target: "_blank",
    },*/
  ];
})

</script>