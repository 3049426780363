import Swal from 'sweetalert2';

const swal = Swal.mixin({
  customClass: {
    actions: 'w-100 justify-content-end gap-2 px-5 py-3',
    confirmButton: 'p-button p-component p-button-rounded',
    cancelButton: 'p-button p-component p-button-rounded p-button-outlined'
  },
  buttonsStyling: false,
  reverseButtons: true
});

export const errorSwal = ({ text }) => swal.fire({ title: 'Error!', text, icon: 'error', confirmButtonText: 'Entendido' })
export const infoSwal = ({ text }) => swal.fire({ title: 'Info!', text, icon: 'info', confirmButtonText: 'Entendido' })