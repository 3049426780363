<template>
  <Toast />
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { useGlobalStore } from '@/stores'
import { storeActions } from '@/helpers/helpers'

const store = useGlobalStore()
const toast = useToast()

store.$onAction(({ name, after }) => {
  after(() => {
    // Cuando se ejecute la accion "setError" del store Global, 
    if (name === storeActions.setError) {
      toast.add({
        severity: store.error?.severity || "error",
        detail: store.error?.detail || "Ha ocurrido un error inesperado",
        summary: store.error?.summary || ""
      })
    }
  })
})

</script>

<style lang="scss" scoped></style>