import moment from "moment";

const UUID_PATTERN =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const CALENDAR_EVENT_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const storeActions = {
  restart: "restart",
  _conditionallySetValue: "_conditionallySetValue",
  setLoading: "setLoading",
  setClientExpanded: "setClientExpanded",
  toggleClientSidebar: "toggleClientSidebar",
  toggleScheduleSidebar: "toggleScheduleSidebar",
  toggleSuccessDialog: "toggleSuccessDialog",
  toggleSearchDialog: "toggleSearchDialog",
  setEpisodeId: "setEpisodeId",
  setEpisodeName: "setEpisodeName",
  setIsPublished: "setIsPublished",
  setChannels: "setChannels",
  setEpisodeEventContentType: "setEpisodeEventContentType",
  setEventType: "setEventType",
  addClient: "addClient",
  removeClient: "removeClient",
  setMediaDescription: "setMediaDescription",
  setMedia: "setMedia",
  setMediaType: "setMediaType",
  setMediaName: "setMediaName",
  syncEpisodeClients: "syncEpisodeClients",
  addEpisodeClients: "addEpisodeClients",
  removeAllEpisodeClients: "removeAllEpisodeClients",
  setEpisodeContentAssignationMechanism:
    "setEpisodeContentAssignationMechanism",
  addEpisodeSchedule: "addEpisodeSchedule",
  setError: "setError",
  loadInitialResources: "loadInitialResources",
  loadEpisode: "loadEpisode",
  setLastSavedEpisode: "setLastSavedEpisode",
  episodeCreated: "episodeCreated",
};

export const processesTypes = {
  pueProgramming: "Gev.PublicarPost",
};

export const sorts = {
  name_asc: "name_asc",
  name_desc: "name_desc",
};

export const formStatuses = {
  new: {
    value: "new",
    label: "Nuevo",
    severity: "info",
  },
  saved: {
    value: "saved",
    label: "Guardado",
    severity: "sucess",
  },
  drafted: {
    value: "drafted",
    label: "Borrador",
    severity: "warning",
  },
  error: {
    value: "error",
    label: "Error",
    severity: "error",
  },
};

export const clientStatuses = [
  { status: "En verificacion", color: "tag-verifying" },
  { status: "Duplicada", color: "tag-duplicate" },
  { status: "Suspendida", color: "tag-suspended" },
  { status: "Pendiente", color: "tag-pending" },
  { status: "Procesando", color: "tag-processing" },
  { status: "Verificado", color: "tag-verified" },
];

const channelNames = {
  gbp: "gbp",
  faceboook: "facebook",
  instagram: "instagram",
  //linkedIn: "linkedIn"
};

const eventTypesNames = {
  /* post: 'post',
  photo: "photo", */
  photoAndPost: "photoAndPost",
  video: "video",
  menu: "menu",
  /* podcast: "podcast",
  event: "event",
  product: "product",
  offer: "offer",
  link: "link",
  catalog: "catalog",
  news: "news",
  article: "article", */
};

export const defaultEventTypes = {
  /* [eventTypesNames.post]: { name: eventTypesNames.post, label: "Post", image: require("@/assets/images/wire-post.svg"), available: true },
  [eventTypesNames.photo]: { name: eventTypesNames.photo, label: "Foto", image: require("@/assets/images/wire-photo.svg"), available: true }, */
  [eventTypesNames.photoAndPost]: {
    name: eventTypesNames.photoAndPost,
    label: "Foto + Post",
    image: require("@/assets/images/wire-photo-post.svg"),
    available: true,
  },
  [eventTypesNames.video]: {
    name: eventTypesNames.video,
    label: "Video",
    image: require("@/assets/images/wire-video.svg"),
    available: false,
  },
  [eventTypesNames.menu]: {
    name: eventTypesNames.menu,
    label: "Carta Menú",
    image: require("@/assets/images/wire-menu-rest.svg"),
    available: false,
  },
  /* [eventTypesNames.podcast]: { name: eventTypesNames.podcast, label: "Podcast", image: require("@/assets/images/wire-podcast.svg"), available: false },
  [eventTypesNames.event]: { name: eventTypesNames.event, label: "Evento", image: require("@/assets/images/wire-event.svg"), available: false },
  [eventTypesNames.product]: { name: eventTypesNames.product, label: "Producto", image: require("@/assets/images/wire-product.svg"), available: false },
  [eventTypesNames.offer]: { name: eventTypesNames.offer, label: "Oferta", image: require("@/assets/images/wire-offert.svg"), available: false },
  [eventTypesNames.link]: { name: eventTypesNames.link, label: "Enlace", image: require("@/assets/images/wire-link.svg"), available: false },
  [eventTypesNames.catalog]: { name: eventTypesNames.catalog, label: "Catálogo", image: require("@/assets/images/wire-catalogue.svg"), available: false },
  [eventTypesNames.news]: { name: eventTypesNames.news, label: "Noticia", image: require("@/assets/images/wire-news.svg"), available: false },
  [eventTypesNames.article]: { name: eventTypesNames.article, label: "Artículo", image: require("@/assets/images/wire-news.svg"), available: false }, */
};

export const defaultChannels = {
  [channelNames.gbp]: {
    name: channelNames.gbp,
    label: "GBP",
    logo: require("@/assets/images/logo-gbp.svg"),
    component: "dtGBP",
    previewComponent: "previewGBP",
    eventTypes: Object.values(eventTypesNames),
  },
  [channelNames.faceboook]: {
    name: channelNames.faceboook,
    label: "Facebook",
    logo: require("@/assets/images/logo-fbk.svg"),
    component: "dtFacebook",
    previewComponent: "previewFacebook",
    eventTypes: Object.values(eventTypesNames),
  },
  [channelNames.instagram]: {
    name: channelNames.instagram,
    label: "Instagram",
    logo: require("@/assets/images/logo-ing.svg"),
    component: "dtInstagram",
    previewComponent: "previewInstagram",
    eventTypes: [eventTypesNames.photoAndPost, eventTypesNames.video],
    //eventTypes: [eventTypesNames.photo, eventTypesNames.photoAndPost, eventTypesNames.video]
  },
  /*
  [channelNames.linkedIn]: {
    name: channelNames.linkedIn,
    label: "Linkedin",
    logo: require("@/assets/images/logo-lnk.svg"),
    component: "dtLinkedin",
    previewComponent: "previewLinkedin",
    eventTypes: Object.values(eventTypesNames)
  }
  */
};

export const defaultEventContentTypes = [
  { name: "Genérico", value: "generic" },
  { name: "Propio", value: "own" },
];
export const defaultcontentAssignationMechanisms = [
  { name: "Piloto automático", value: "autopilot" },
  { name: "Manual", value: "manual" },
];

export const getSeverity = (status) => {
  const clientElement = clientStatuses.find(
    (element) => element.status == status
  );
  return clientElement?.color || "";
};

export const countries = [
  {
    name: "España",
    iso2: "ES",
    iso3: "ESP",
  },
  {
    name: "Colombia",
    iso2: "CO",
    iso3: "COL",
  },
  {
    name: "Perú",
    iso2: "PE",
    iso3: "PER",
  },
  {
    name: "México",
    iso2: "MX",
    iso3: "MEX",
  },
  {
    name: "Estados Unidos",
    iso2: "US",
    iso3: "USA",
  },
];

export const countryNames = {
  es: "España",
  co: "Colombia",
  pe: "Perú",
  mx: "México",
  us: "Estados Unidos",
};

export const scheduleTypesValues = {
  onetime: "onetime",
  recurrent: "recurrent",
};

export const scheduleTypeOptions = [
  { name: "Una vez", value: scheduleTypesValues.onetime },
  { name: "Recurrente", value: scheduleTypesValues.recurrent },
];

export const recurrentFrequencyValues = {
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
};

export const monthlyOccurrenceTypes = {
  monthday: "monthday",
  weekday: "weekday",
};

export const dailyFrequenciesValues = {
  once: "once",
  every: "every",
};

export const recurrentFrequencyOptions = [
  { name: "Diario", value: "daily" },
  { name: "Semanal", value: "weekly" },
  { name: "Mensual", value: "monthly" },
];

export const weekdays = [
  { name: "Lunes", value: "1" },
  { name: "Martes", value: "2" },
  { name: "Miércoles", value: "3" },
  { name: "Jueves", value: "4" },
  { name: "Viernes", value: "5" },
  { name: "Sábado", value: "6" },
  { name: "Domingo", value: "0" },
];

export const monthlyOccurrenceWeekayNumberOptions = [
  { name: "Primer", value: "1" },
  { name: "Segundo", value: "2" },
  { name: "Tercer", value: "3" },
  { name: "Cuarto", value: "4" },
  { name: "Quinto", value: "5" },
  { name: "Último", value: "last" },
];

export const monthlyOccurrenceWeekdayOptions = [
  { name: "Lunes", value: "1" },
  { name: "Martes", value: "2" },
  { name: "Miércoles", value: "3" },
  { name: "Jueves", value: "4" },
  { name: "Viernes", value: "5" },
  { name: "Sábado", value: "6" },
  { name: "Domingo", value: "0" },
  { name: "Día", value: "1-5" },
  { name: "Fin de semana", value: "6-7" },
];

export const happensEveryMetricValues = {
  hours: "hours",
  minutes: "minutes",
  seconds: "seconds",
};

export const happensEveryMetricOptions = [
  { name: "Horas", value: happensEveryMetricValues.hours },
  { name: "Minutos", value: happensEveryMetricValues.minutes },
  { name: "Segundos", value: happensEveryMetricValues.seconds },
];

export const formatDate = (date) => {
  const newDate = new Date(date).toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return newDate;
};

export const aggregations = {
  day: "raw",
  week: "raw",
  month: "daily",
  year: "monthly",
};

export const doPlural = (str, times, wcd) => {
  if (times > 1) {
    return str + (wcd || "s");
  } else {
    return str;
  }
};

export const responsiveContent = [
  { breakpoint: "2300px", numVisible: 11, numScroll: 2 },
  { breakpoint: "2020px", numVisible: 10, numScroll: 2 },
  { breakpoint: "1800px", numVisible: 9, numScroll: 2 },
  { breakpoint: "1690px", numVisible: 8, numScroll: 2 },
  { breakpoint: "1520px", numVisible: 7, numScroll: 2 },
  { breakpoint: "1320px", numVisible: 6, numScroll: 2 },
  { breakpoint: "1180px", numVisible: 5, numScroll: 2 },
  { breakpoint: "992px", numVisible: 4, numScroll: 3 },
  { breakpoint: "700px", numVisible: 3, numScroll: 3 },
];

export const getThumbContent = () => {
  return { name: "Post", image: require("@/assets/images/wire-post.svg") };
};

export const clientsListToTreeNodes = (clientsList) => {
  if (!clientsList) return null;
  return clientsList.map((client, index) => {
    return {
      key: index,
      label: client.name,
      children: [
        {
          key: index + "-" + index,
          label: client,
          data: [client],
          type: "edata",
        },
      ],
    };
  });
};

export const env = {
  PUE_API_URL: window.config.PAU,
  REDA_URL: window.config.RU,
  GEV_API_URL: window.config.GAU,
  CLIENTS_API_URL: window.config.CAU,
  ENTITIES_API_URL: window.config.EAU,
};

export const isUUID = (string) => UUID_PATTERN.test(string);

export function debounce(fn, wait) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    const context = this; // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, wait);
  };
}

export const getChannelsData = (channelsNames) =>
  channelsNames?.map((ch) => defaultChannels[ch]);
export const getEventTypesData = (eventTypesNames) =>
  eventTypesNames?.map((ch) => defaultEventTypes[ch]);

export const repeatedElements = (array, repsNumber) => {
  const counter = {};
  const filteredElements = [];

  for (const element of array) {
    counter[element] = (counter[element] || 0) + 1;
  }

  for (const element in counter) {
    if (counter[element] === repsNumber) {
      filteredElements.push(element);
    }
  }

  return filteredElements;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const serializeEpisode = (episode) => {
  const serializedEpisode = {
    episodeId: episode.episodeId,
    episodeName: episode.episodeName,
    channels: episode.channels,
    eventType: episode.eventType,
    eventContentType: episode.eventContentType,
    clients: episode.clients.map((c) => c.location.clientId),
    content: [
      {
        type: episode.mediaType,
        value: episode.media,
        name: episode.mediaName,
        metadata: [{}],
      },
    ],
    post_text: episode.mediaDescription || "",
    schedules: episode.schedules,
  };

  return serializedEpisode;
};

export const sleep = (delay) =>
  new Promise((resolve) => setTimeout(resolve, delay));

const frequencyStartsAt = (schedule) =>
  `${schedule.everyFrequencyStartsAtHour}:${schedule.everyFrequencyStartsAtMinute}:${schedule.everyFrequencyStartsAtSecond}`;
const frequencyEndsAt = (schedule) =>
  `${schedule.everyFrequencyEndsAtHour}:${schedule.everyFrequencyEndsAtMinute}:${schedule.everyFrequencyEndsAtSecond}`;

export const getSchedulesAttributes = ({ schedule }) => {
  let scheduleAttributes = {};

  const {
    scheduleType,
    oneTimeOccurrenceDate,
    oneTimeOccurrenceTime,
    ...otherScheduleKeys
  } = schedule;

  if (scheduleType === scheduleTypesValues.onetime) {
    scheduleAttributes = {
      scheduleType,
      oneTimeOccurrenceDate: moment(oneTimeOccurrenceDate).format("YYYYMMDD"),
      oneTimeOccurrenceTime: moment(oneTimeOccurrenceTime).format("HH:mm:ss"),
      timezone: USER_TIMEZONE,
    };
  } else {
    scheduleAttributes = {
      ...otherScheduleKeys,
      scheduleType,
      everyFrequencyStartsAt: frequencyStartsAt(schedule),
      everyFrequencyEndsAt: frequencyEndsAt(schedule),
      startDate: moment(otherScheduleKeys.startDate).format("YYYYMMDD"),
      endDate: otherScheduleKeys.endDate
        ? moment(otherScheduleKeys.endDate).format("YYYYMMDD")
        : null,
      weeklyWeekday: otherScheduleKeys.weeklyWeekday
        .map((ww) => parseInt(ww))
        .sort(),
      dailyFrequencyTime:
        otherScheduleKeys.dailyFrequencyTime == ""
          ? frequencyStartsAt(schedule)
          : moment(otherScheduleKeys.dailyFrequencyTime).format("HH:mm:ss"),
      timezone: USER_TIMEZONE,
    };
  }

  return scheduleAttributes;
};

export const nextExecutionsDatesToCalendarEvents = (nextDates) => {
  if (!nextDates || !nextDates?.length) {
    return [];
  }

  const calendarEvents = [];
  nextDates.forEach((nextDate) => {
    // if nextDate is a string, it'll be a date like this 2024-09-10T13:37:23
    if (typeof nextDate === "string") {
      const date = moment(nextDate);
      const event = {
        start: date.format(CALENDAR_EVENT_FORMAT),
        end: date.add(1, "h").format(CALENDAR_EVENT_FORMAT),
      };
      calendarEvents.push(event);
    } else if (nextDate.count) {
      // else if its an object like this { "date": "2024-09-10", "count": 1 }
      for (let i = 0; i < nextDate.count; i++) {
        const date = moment(nextDate.date);
        const event = {
          start: date.format(CALENDAR_EVENT_FORMAT),
          end: date.add(1, "h").format(CALENDAR_EVENT_FORMAT),
        };
        calendarEvents.push(event);
      }
    } else {
      // else is going to be an objet like this { "date": "2024-09-10T13:37:23", "id": 1 }
      const date = moment(nextDate.date);
      const event = {
        start: date.format(CALENDAR_EVENT_FORMAT),
        end: date.add(1, "h").format(CALENDAR_EVENT_FORMAT),
      };
      calendarEvents.push(event);
    }
  });

  return calendarEvents;
};
