<template>
  <div class="drop-zone-container">
    <div>
      <h5>Imagen</h5>
    </div>
    <div class="image-container d-flex flex-column align-items-center justify-content-center" v-if="imageUrl">
      <img class="image" :src="imageUrl" />
      <Button class="image-delete-button" @click="onDeleteImage" icon="pi pi-times" severity="danger" rounded
        aria-label="Eliminar" />
    </div>
    <div v-else class="drop-zone d-flex justify-content-center align-items-center"
      :class="{ 'drop-zone-active': isDragActive }" v-bind="getRootProps()">
      <input v-bind="getInputProps()" />
      <div class="spinner-border" role="status" v-if="isUploadingMedia">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-else>
        <p v-if="isDragActive">Suelta la imagen aqu&iacute;</p>
        <p v-else>Arrastra y suelta la imagen, o haz click aqu&iacute;</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useDropzone } from "vue3-dropzone";
import Compressor from 'compressorjs'
import { storeActions, toBase64 } from '@/helpers/helpers'
import { errorSwal } from '@/helpers/swal'
import { useGlobalStore } from '@/stores';

const imageUrl = ref('');
const isUploadingMedia = ref(false);

const store = useGlobalStore()

const onDeleteImage = () => {
  imageUrl.value = '';
  store.setMedia('');
}

const FIVE_MB = 5242880 // 5 Megabytes

const convertAndSaveFile = async (file) => {
  // es posible que al comprimir la imagen, el resultado sea una imagen de más de 5MB
  // en cuyo caso, mostramos error y punto.
  if (file.size > FIVE_MB) {
    isUploadingMedia.value = false;
    errorSwal({ text: "La imagen debe tener un tamaño menor a 5 MB" })
    return;
  }
  // convert it to Base64 string and
  // assign it to ref so it can be rendered to the screen
  imageUrl.value = await toBase64(file)
  // stop showing the loader
  isUploadingMedia.value = false;
  // save it in the store
  store.setMedia(imageUrl.value);
  store.setMediaType(file.type);
  store.setMediaName(file.name);
}

const onDrop = async (acceptFiles, rejectReasons) => {
  try {
    if (rejectReasons.length !== 0) {
      errorSwal({ text: JSON.stringify(rejectReasons) })
      return;
    }
    // show a loader
    isUploadingMedia.value = true;

    // get the uploaded image
    const file = acceptFiles[0];
    // compress it if bigger than 5MB
    if (file.size > FIVE_MB) {
      new Compressor(file, {
        quality: 0.8,
        success: convertAndSaveFile
      })
    } else {
      await convertAndSaveFile(file)
    }
  } catch (error) {
    errorSwal({ text: error?.message || 'Error al mostrar imagen seleccionada' })
  }
}

const options = { onDrop, accept: ['image/*'] }

const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

store.$onAction(({ name, after }) => {
  if (name === storeActions.restart) {
    imageUrl.value = ''
  }

  after(() => {
    if (name === storeActions.loadEpisode) {
      imageUrl.value = store.episode.media
    }
  })
})

</script>

<style>
.drop-zone-container {
  --image-height: 400px;
  --containers-radius: 6px;
}

.image-container {
  position: relative;
  height: var(--image-height);
  border: 2px solid var(--guo-color-gray);
  border-radius: var(--containers-radius);
  overflow: hidden;
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: 'auto';
  height: 'auto';
}

.image-delete-button {
  width: 200px;
}

.image-container button {
  position: absolute;
  top: 2%;
  right: 2%;
}

.drop-zone {
  min-height: var(--image-height);
  border: 2px dashed var(--guo-color-gray);
  border-radius: var(--containers-radius);
  cursor: pointer;
}

.drop-zone-active {
  border-style: solid;
  border-color: var(--guo-color-primary)
}
</style>