import keycloak from "./keycloak";

async function initKeycloak(app) {
  try {
    const authenticated = await keycloak.init({ onLoad: "login-required" });
    if (!authenticated) {
      window.location.reload();
    } else {
      app.mount("#app");

      localStorage.setItem("vue-token", keycloak.token);
      localStorage.setItem("vue-refresh-token", keycloak.refreshToken);

      setInterval(() => {
        keycloak
          .updateToken(70)
          .then((refreshed) => {
            if (refreshed) {
              localStorage.setItem("vue-token", keycloak.token);
              localStorage.setItem("vue-refresh-token", keycloak.refreshToken);
            }
          })
          .catch(() => {
            console.log("Failed to refresh token");
          });
      }, 60000);
    }
  } catch (error) {
    console.log("Authentication Failed", error);
  }
}

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import "@/assets/css/app.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import i18n from "./i18n";

import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";

import PrimeVue from "primevue/config";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Badge from "primevue/badge";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Carousel from "primevue/carousel";
import Checkbox from "primevue/checkbox";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import FocusTrap from "primevue/focustrap";
import InlineMessage from "primevue/inlinemessage";
import Inplace from "primevue/inplace";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Menu from "primevue/menu";
import OverlayPanel from "primevue/overlaypanel";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import SelectButton from "primevue/selectbutton";
import Sidebar from "primevue/sidebar";
import StyleClass from "primevue/styleclass";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tag from "primevue/tag";
import Tooltip from "primevue/tooltip";
import Tree from "primevue/tree";
import ContextMenu from "primevue/contextmenu";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Divider from "primevue/divider";

import FlagIcon from "vue-flag-icon";

import previewGBP from "@/components/bundles/previewGBP.vue";
import previewFacebook from "@/components/bundles/previewFacebook.vue";
import previewInstagram from "@/components/bundles/previewInstagram.vue";
import previewLinkedin from "@/components/bundles/previewLinkedin.vue";
import dtGBP from "@/components/bundles/dtGBP.vue";
import dtFacebook from "@/components/bundles/dtFacebook.vue";
import dtInstagram from "@/components/bundles/dtInstagram.vue";
import dtLinkedin from "@/components/bundles/dtLinkedin.vue";

import TimeInput from "./components/timeInput/TimeInput.vue";

//tabs
import TabHeader from "./components/formTabs/tabHeader/TabHeader.vue";
import Main from "./components/Main.vue";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(i18n);
app.use(PrimeVue, {
  ripple: true,
  zIndex: {
    modal: 1100,
    overlay: 1000,
    menu: 1000,
    tooltip: 1100,
  },
});
app.use(ToastService);
app.use(FlagIcon);

app.directive("tooltip", Tooltip);
app.directive("ripple", Ripple);
app.directive("styleclass", StyleClass);
app.directive("focustrap", FocusTrap);

app
  .component("previewGBP", previewGBP)
  .component("previewFacebook", previewFacebook)
  .component("previewInstagram", previewInstagram)
  .component("previewLinkedin", previewLinkedin)
  .component("dtGBP", dtGBP)
  .component("dtFacebook", dtFacebook)
  .component("dtInstagram", dtInstagram)
  .component("dtLinkedin", dtLinkedin)
  .component("TimeInput", TimeInput)
  .component("TabHeader", TabHeader);

app
  .component("VueCal", VueCal)
  .component("FormWizard", FormWizard)
  .component("TabContent", TabContent);

app
  .component("Accordion", Accordion)
  .component("AccordionTab", AccordionTab)
  .component("Badge", Badge)
  .component("Button", Button)
  .component("Calendar", Calendar)
  .component("Carousel", Carousel)
  .component("Checkbox", Checkbox)
  .component("Column", Column)
  .component("DataTable", DataTable)
  .component("DataView", DataView)
  .component("Dialog", Dialog)
  .component("Dropdown", Dropdown)
  .component("InlineMessage", InlineMessage)
  .component("Inplace", Inplace)
  .component("InputNumber", InputNumber)
  .component("InputSwitch", InputSwitch)
  .component("InputText", InputText)
  .component("Menu", Menu)
  .component("OverlayPanel", OverlayPanel)
  .component("RadioButton", RadioButton)
  .component("SelectButton", SelectButton)
  .component("Sidebar", Sidebar)
  .component("TabView", TabView)
  .component("TabPanel", TabPanel)
  .component("Tag", Tag)
  .component("Textarea", Textarea)
  .component("Toast", Toast)
  .component("Tree", Tree)
  .component("ContextMenu", ContextMenu)
  .component("IconField", IconField)
  .component("InputIcon", InputIcon)
  .component("Divider", Divider);

app.component("Main", Main);

initKeycloak(app);
