<template>
  <div>
    <h5>Descripci&oacute;n</h5>
    <Textarea class="flex-grow-1" v-model="mediaDescription" rows="5" cols="30" ref="textarea"
      @change="handleMediaDescriptionChange" @keyup="handleMediaDescriptionKeyup" @contextmenu="openContextMenu" />
    <ContextMenu ref="menu" :model="menuItems" class="content-menu">
      <template #item="{ item, props }">
        <a class="menu-item mb-2 ps-2" v-bind="props.action">
          <p class="m-0">{{ item.label }}</p>
          <b class="d-block">/{{ item.tag }}</b>
        </a>
      </template>
    </ContextMenu>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import getCaretCoordinates from 'textarea-caret';
import { useGlobalStore } from '@/stores';
import services from '@/helpers/services'
import { storeActions } from '@/helpers/helpers';

const mediaDescription = ref('')
const textarea = ref()
const menu = ref()
const menuItems = ref([])

const store = useGlobalStore();

const handleMediaDescriptionChange = () => store.setMediaDescription(mediaDescription.value)

const handleMediaDescriptionKeyup = (keyboardEvent) => {
  if (keyboardEvent.key === '/') {
    const textareaEl = textarea.value.$el;
    const rect = textareaEl.getBoundingClientRect();
    const caret = getCaretCoordinates(textareaEl, textareaEl.selectionEnd)

    const x = caret.left + rect.left;
    const y = caret.top + caret.height + rect.top;

    const pointerEvent = new MouseEvent('contextmenu', {
      bubbles: true,
      cancelable: true,
      composed: false,
      clientX: x,
      clientY: y,
      pageX: x,
      pageY: y,
    })

    textareaEl.dispatchEvent(pointerEvent);
  }
}

const openContextMenu = (event) => menu.value.show(event)

const handleMenuItemClick = (tag) => {
  // check if the last char is a slash or not
  const lastTypedChar = mediaDescription.value ? mediaDescription.value.slice(-1) : "";
  const tagToAdd = lastTypedChar === '/' ? tag : `/${tag}`;

  // then append the new tag to the whole text
  mediaDescription.value += tagToAdd

  // update the store property
  handleMediaDescriptionChange()

  // move the cursor to the end of the text and focus the textarea
  textarea.value.$el.selectionEnd = mediaDescription.value.length
  textarea.value.$el.focus();
}

onMounted(async () => {
  const mediaDescriptionVars = await services.getMediaDescriptionVars()
  menuItems.value = mediaDescriptionVars.map((mdv) => {
    mdv.command = () => handleMenuItemClick(mdv.tag)
    return mdv
  })
})

store.$onAction(({ name, after }) => {
  if (name === storeActions.restart) {
    mediaDescription.value = ''
  }

  after(() => {
    if (name === storeActions.loadEpisode) {
      mediaDescription.value = store.episode.mediaDescription || ""
    }
  })
})
</script>

<style lang="scss" scoped>
.menu-item {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: initial;
}

.menu-item b {
  color: var(--guo-color-gray)
}
</style>