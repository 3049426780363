<template>
  <div class="card-body p-0 justify-content-start">
    <TabHeader title="¿Cuál es el contenido?" class="mt-3" />
    <div class="grid-container" :class="{ 'three-columns': !store.hasSingleClient}">
      <DropZone />
      <ContentDescription class="d-flex flex-column" />
      <template v-if="!store.hasSingleClient">
        <ContentVariables />
      </template>
    </div>
  </div>
</template>

<script setup>
import DropZone from '@/components/dropZone/DropZone.vue'
import ContentDescription from '@/components/contentDescription/ContentDescription.vue'
import ContentVariables from '@/components/contentVariables/ContentVariables.vue'
import { useGlobalStore } from '@/stores';

const store = useGlobalStore();

</script>

<style lang="scss" scopeds>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  &.three-columns {
    grid-template-columns: 1fr 1fr 120px;
  }
}
</style>