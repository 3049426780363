<template>
  <div class="card-body p-0">
    <TabHeader title="¿Qué tipo de evento quieres gestionar?" />
    <!--
    <div class="d-flex flex-column align-items-center justify-content-center">
      <label class="form-label mb-2">Tipo de contenido</label>
      <SelectButton aria-labelledby="Seleccionar el tipo de contenido" class="nav-group nav-group-outline rounded-pill"
        v-model="store.eventContentType" :options="defaultEventContentTypes" optionLabel="name" optionValue="value"
        :unselectable="true" @change="handleEventContentTypeChange" />
    </div>
    -->
    <div class="sec-contents d-flex flex-column align-items-center justify-content-center">
      <div class="wrap-channels">
        <Carousel :value="store.eventTypesData" :numVisible="8" :numScroll="3" :pt="{
          indicatorButton: { class: 'rounded-pill' },
          container: { class: 'mb-3' },
          indicators: { class: 'gap-2' },
          indicator: { class: 'm-0' },
        }">
          <template #item="slotProps">
            <div class="content-item" role="presentation">
              <a class="box-item d-flex flex-column overflow-hidden"
                v-tooltip.top="!slotProps.data.available && 'No disponible'"
                :class="{ active: isSelected(slotProps.data.name), available: slotProps.data.available }" role="tab"
                :data-channel="slotProps.data.name" @click="handleEventTypeSelected(slotProps.data)">
                <div class="box-img d-flex justify-content-center">
                  <img :src="`${slotProps.data.image}`"
                    :alt="`Representación gráfica para el tipo de contenido ${slotProps.data.label}`" />
                </div>
                <span class="box-text fw-bold fs-6 lh-1">{{ slotProps.data.label }}</span>
              </a>
            </div>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useGlobalStore } from '@/stores';
//import { defaultEventContentTypes } from '@/helpers/helpers'

const store = useGlobalStore()

const handleEventTypeSelected = (eventType) => {
  if (!eventType.available) return;
  store.setEventType(eventType.name)
}
/*
const handleEventContentTypeChange = ({ value }) => {
  store.setEpisodeEventContentType(value)
}
*/
const isSelected = (eventTypeName) => {
  return store.eventType === eventTypeName
}
</script>

<style scoped>
.wrap-channels {
  position: relative;
  max-width: 1200px;
}

.content-item {
  width: 120px;
  min-height: 100px;
}

.box-item {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100px;
  padding: 10px;
  background-color: transparent;
  border: 1px dashed var(--bs-gray-500);
  border-radius: 0.625rem;
  user-select: none;
}

.box-item.active {
  background-color: var(--bs-primary-light);
  border: 2px solid var(--guo-color-primary);
}

.box-item.available:hover {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary-light);
  cursor: pointer;
}

.box-item:not(.available) {
  filter: grayscale(100%);
  background-color: rgb(233 234 245 / 64%);
  opacity: 0.5;
  pointer-events: none;
}

.box-item.active .box-text {
  color: var(--guo-color-primary) !important;
}

.box-item.available:hover .box-text {
  color: var(--bs-primary);
}
</style>