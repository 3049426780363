<template>
  <div>
    <Button class="user-menu" :label="userName" icon="pi pi-angle-down" iconPos="right" @click="toggle"
      aria-haspopup="true" aria-controls="overlay_menu" text />
    <Menu ref="menu" id="overlay_menu" :model="items" :popup="true">
      <template #item="{ item, props }">
        <component v-if="item.component" :is="item.component"></component>
        <a v-else v-ripple class="flex align-items-center gap-3" v-bind="props.action">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </template>
    </Menu>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import keycloak from '../../keycloak';
import ThemeSwitch from "@/components/bundles/themeSwitch.vue";

const userName = ref("")
const menu = ref();
const items = [
  {
    items: [
      {
        component: ThemeSwitch
      },
      {
        separator: true
      },
      {
        label: 'Ajustes de cuenta',
        icon: 'pi pi-cog',
        command: () => {
          window.open(accountUrl.value, { target: "_blank" })
        }
      },
      {
        label: 'Cerrar sesión',
        icon: 'pi pi-sign-out',
        command: () => {
          logout()
        }
      }
    ]
  }
];

const accountUrl = computed(() => {
  // Construir la URL de redirección con el parámetro redirect_uri
  const redirectUri = encodeURIComponent(window.location.origin);
  return `${keycloak.authServerUrl}/realms/${keycloak.realm}/account?referrer=${keycloak.clientId}&referrer_uri=${redirectUri}`;
})

const setUsername = () => {
  if (keycloak.tokenParsed) {
    userName.value = keycloak.tokenParsed.preferred_username;
  }
}

const toggle = (event) => {
  menu.value.toggle(event);
};

const logout = () => keycloak.logout()

onMounted(() => {
  setUsername()
})

</script>

<style lang="scss" scoped>
.user-menu {
  border: none;
  color: white;

  &:hover {
    color: white !important;
  }

}

#overlay_menu {
  a {
    text-decoration: none;
    color: var(--bd-body-color);
  }
}
</style>