<template>
  <header class="header align-items-stretch">
    <div class="container-xxl d-flex align-items-center">
      <div class="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
        <LogoGuo @click="store.restart()" />
      </div>
      <div class="flex-column me-3">
        <h1 class="text-white m-0" @click="store.restart()">Gestor de Eventos</h1>
      </div>
      <div class="topbar d-flex align-items-center justify-content-end flex-lg-grow-1 gap-2">
        <IconField iconPosition="left">
          <InputIcon class="pi pi-search"> </InputIcon>
          <InputText class="pointer" placeholder="Buscar episodio" @click="store.toggleSearchDialog(true)" />
        </IconField>
        <ButtonMoreApps />
        <!-- <ButtonLang /> -->
        <UserMenu />
      </div>
    </div>
  </header>
</template>

<script setup>
import LogoGuo from "@/components/bundles/logoGuo.vue";
import ButtonMoreApps from "@/components/bundles/buttonMoreApps.vue";
import UserMenu from "../user/UserMenu.vue";
//import ButtonLang from "@/components/bundles/buttonLang.vue";
import { useGlobalStore } from '@/stores';

const store = useGlobalStore()

</script>

<style lang="scss" scoped>
h1 {
  cursor: pointer;
}
</style>