import { defineStore } from "pinia";

export const colorSchemes = {
  light: "light",
  dark: "dark",
};

export const useThemeStore = defineStore("theme", {
  state: () => {
    return {
      theme: colorSchemes.light,
      themeSet: false,
    };
  },
  actions: {
    getMediaPreference() {
      const media = window.matchMedia("(prefers-color-scheme: dark)");
      const hasDarkPreference = media.matches;
      return hasDarkPreference ? colorSchemes.dark : colorSchemes.light;
    },
    setTheme(theme) {
      localStorage.setItem("data-bs-theme", theme);
      this.theme = theme;
      document.documentElement.setAttribute("data-bs-theme", theme);
    },
    getTheme() {
      if (document.documentElement.hasAttribute("data-bs-theme")) {
        return document.documentElement.getAttribute("data-bs-theme");
      } else if (localStorage.getItem("data-bs-theme") !== null) {
        return localStorage.getItem("data-bs-theme");
      } else {
        return this.theme;
      }
    },
    toggleTheme(event) {
      this.themeSet = event.target.checked;
      localStorage.getItem("data-bs-theme") === colorSchemes.light
        ? this.setTheme(colorSchemes.dark)
        : this.setTheme(colorSchemes.light);
    },
  },
});
