<template>
  <svg id="logo_guo" data-name="logo guo" xmlns="http://www.w3.org/2000/svg" class="brand-logo logo-default"
    viewBox="0 0 732.06 266.55">
    <defs></defs>
    <path class="cls-1"
      d="M123.61,121.36H224.5V236.75H203v-50q-24.79,50.67-82.64,50.87-45.11,0-75.52-29.92T14.47,133.33q0-44.25,30.35-74.24t75.52-29.92c23.94,0,45,6.41,63.41,19.23s30.35,29.64,36.19,50H187.17q-9.4-15.18-26.93-23.94c-11.83-5.84-25.08-8.83-39.9-8.83-22,0-40.19,6.41-54.86,19.23C50.8,97.85,43.39,114,43.39,133.33S50.8,169,65.48,181.78s32.91,19.38,54.86,19.38c18.38,0,34.34-4.28,48-12.69,13.53-8.4,22.65-19.37,27.07-32.91H123.61Z" />
    <path class="cls-1"
      d="M557.84,237.63q-45.32,0-75.67-29.92t-30.35-74.38q0-44.25,30.35-74.24t75.67-29.92q45.09,0,75.52,29.92t30.35,74.24q0,44.46-30.35,74.38T557.84,237.63Zm0-36.47c21.94,0,40.32-6.42,55-19.38s21.94-28.93,21.94-48.45-7.26-35.48-21.94-48.45c-14.68-12.82-33.06-19.23-55-19.23-22.09,0-40.33,6.41-55,19.23-14.68,13-22.09,29.07-22.09,48.45s7.41,35.62,22.09,48.45S535.75,201.16,557.84,201.16Z" />
    <path class="cls-1"
      d="M394.73,65.69v90.17c0,13-5.42,23.79-16.25,32.34s-24.29,13-40.39,13-29.71-4.27-40.4-13-16.1-19.37-16.1-32.34V65.7H253.2v90.73c0,23.22,8.05,42.46,24.3,58s36.44,23.22,60.59,23.22c24.44,0,44.64-7.69,60.88-23.22s24.15-34.76,24.15-58V65.69Z" />
    <rect class="cls-1" x="281.66" y="29.17" width="36.52" height="36.52" />
    <rect class="cls-1" x="358.26" y="29.17" width="36.52" height="36.52" />
    <path class="cls-1"
      d="M686.28,34a17.78,17.78,0,0,1,12.86-5.1,18.27,18.27,0,0,1,13.07,5.14A17.1,17.1,0,0,1,717.6,47a17,17,0,0,1-5.39,12.82,18.23,18.23,0,0,1-13.07,5.14,17.81,17.81,0,0,1-12.9-5.14A17.09,17.09,0,0,1,680.93,47,17.26,17.26,0,0,1,686.28,34Zm2.34,23.72a14.21,14.21,0,0,0,10.52,4.09,14.77,14.77,0,0,0,10.73-4.09A14.23,14.23,0,0,0,714.09,47a14.33,14.33,0,0,0-15-15,14.27,14.27,0,0,0-10.56,4.14A14.72,14.72,0,0,0,684.44,47,14.28,14.28,0,0,0,688.62,57.73ZM698.89,37.1a8.67,8.67,0,0,1,6.26,2.09,7,7,0,0,1,2.18,5.26,6.49,6.49,0,0,1-3.85,6.35l3.85,5.84h-4.26L700,51.72h-3.51v4.92h-3.84V37.1Zm-2.42,11.61h2.42c3,0,4.51-1.37,4.51-4.09s-1.5-4.26-4.51-4.26h-2.42Z" />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  cursor: pointer;
}
</style>