<template>
  <div class="wrap-loader" v-if="store.loading">
    <div class="loader"></div>
  </div>
</template>

<script setup>
import { useGlobalStore } from '@/stores'

const store = useGlobalStore()
</script>

<style lang="scss" scoped></style>