<template>
  <div class="wrap-profile d-flex flex-column pb-2">
    <div class="header-gbp">
      <div class="cover-gbp d-flex">
        <div class="cover-vert">
          <div class="cover-thumb">
            <img
              class="img-fluid"
              src="@/assets/images/gbp/cover-thumb-vert.jpg"
              alt=""
            />
            <span class="view-img">Ver fotos</span>
          </div>
        </div>
        <div class="cover-horz">
          <div class="wrap-map-preview">
            <img
              class="img-fluid"
              src="@/assets/images/gbp/preview-map-gbp.png"
              alt=""
            />
            <div class="box-action-map">
              <img
                class="img-fluid"
                src="@/assets/images/gbp/action-map-gbp.png"
                alt=""
              />
            </div>
          </div>
          <div class="cover-thumb-h">
            <img
              class="img-fluid"
              src="@/assets/images/gbp/cover-thumb-horz.jpg"
              alt=""
            />
            <span class="view-img">Ver exterior</span>
          </div>
        </div>
      </div>
      <div class="caption-header">
        <div class="wrap-title px-box">
          <div class="title">Pizza Natura saludable y sin gluten</div>
          <div class="box-gbp-actions d-flex mt-1 mb-3">
            <a
              href="javascript:void(0);"
              class="gbp-action"
              target="_blank"
              title="Serás redirigido a la ficha"
              >Ver ficha</a
            >
            <a
              href="javascript:void(0);"
              class="gbp-action"
              target="_blank"
              title="Serás redirigido para editar la ficha"
              >Editar ficha</a
            >
          </div>
        </div>
        <div class="wrap-reviews px-box d-flex align-items-center">
          <span class="score">4,7</span>
          <span
            class="score-stars"
            aria-label="Valoración de 4,7 de un máximo de 5,"
            role="img"
          >
            <span style="width: 62px"></span>
          </span>
          <span class="score-link"
            ><a class="gbp-link" href="#">1.956 reseñas de Google</a></span
          >
          <span class="score-info">
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
              ></path>
            </svg>
          </span>
        </div>
        <div class="gbp-feature px-box">Restaurante de comida saludable</div>
      </div>
    </div>
    <div class="body-gbp px-box">
      <span class="bd-text"
        >Restaurante refinado y acogedor de pizzas innovadoras y sin
        gluten.</span
      >
      <div class="feature-opt">
        <b>Opciones de servicio:</b
        ><span
          >Comer allí&nbsp;· Recogida sin entrar&nbsp;· Entrega sin
          contacto</span
        >
      </div>
      <div class="feature-opt">
        <b>Dirección:</b><span>C. de Jovellanos, 6, 28014 Madrid</span>
      </div>
      <div class="feature-opt">
        <b>Horario:</b>
        <span class="wrap-txt-hour" @click="toggle">
          <span><span class="txt-hour">Abierto</span> ⋅ Cierra a las 0:00</span>
          <span class="txt-arrow"></span>
        </span>
        <OverlayPanel ref="op">
          <table class="tb-hours">
            <tbody>
              <tr class="tb-hours-active">
                <td class="tb-hours-col">miércoles</td>
                <td>13:00-17:00, 20:00-24:00</td>
              </tr>
              <tr>
                <td class="tb-hours-col">jueves</td>
                <td>13:00-17:00, 20:00-24:00</td>
              </tr>
              <tr>
                <td class="tb-hours-col">viernes</td>
                <td>13:00-17:00, 20:00-24:00</td>
              </tr>
              <tr>
                <td class="tb-hours-col">sábado</td>
                <td>13:00-17:00, 20:00-24:00</td>
              </tr>
              <tr>
                <td class="tb-hours-col">domingo</td>
                <td>13:00-17:00, 20:00-24:00</td>
              </tr>
              <tr>
                <td class="tb-hours-col">lunes</td>
                <td>13:00-17:00, 20:00-24:00</td>
              </tr>
              <tr>
                <td class="tb-hours-col">martes</td>
                <td>13:00-17:00, 20:00-24:00</td>
              </tr>
            </tbody>
          </table>
        </OverlayPanel>
      </div>
      <div class="feature-opt">
        <b>Menú:</b
        ><a
          class="gbp-link"
          href="https://www.pizzanatura.es/carta-web/"
          target="_blank"
          title="Será redirigido al sitio web"
          >pizzanatura.es</a
        >
      </div>
      <div class="feature-opt">
        <b>Teléfono:</b><span class="gbp-link">919 22 33 75</span>
      </div>
      <div class="feature-opt">
        <b>Reservas:</b
        ><a
          class="gbp-link"
          href="https://www.pizzanatura.es/reservas/"
          target="_blank"
          title="Será redirigido al sitio web"
          >pizzanatura.es</a
        >
      </div>
    </div>
    <div class="body-gbp px-box">
      <div class="sub-title">De Pizza Natura saludable y sin gluten</div>
      <div class="bd-text">
        "Pizza natura nació para cambiar el mundo, queremos que te comas una
        pizza saludable de verdad con ingredientes ecológicos. DÉJATE SORPRENDER
        Pizza con masa de mijo y quinoa 100% saludable, nutritiva y ecológica
        sin azucares ni harinas refinadas, pizza libre de gluten (gluten free).
        Un lugar donde vivirás una experiencia"
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },
  },
};
</script>
<style scoped></style>
