<template>
  <div v-if="variables.length">
    <div class="d-flex justify-content-start align-items-baseline gap-1">
      <h5>Variables</h5>
      <i class="help-icon pi pi-info-circle ms-2" @click="(event) => helpOP.toggle(event)"></i>
    </div>
    <ul class="m-0 p-0">
      <li class="mb-4" v-for="variable in variables" :key="variable.tag">
        <span class="d-block">{{ variable.label }}</span>
        <span class="d-block">/{{ variable.tag }}</span>
      </li>
    </ul>

    <OverlayPanel ref="helpOP">
      <div class="help-overlay-panel">
        <p>Puedes agregar variables a la descripci&oacute;n introduciendo el s&iacute;mbolo "/" o haciendo click derecho sobre
          el &aacute;rea de texto.</p>
        <p>Estas variables se cambiaran autom&aacute;ticamente por su valor correspondiente al momento de la
          publicaci&oacute;n.</p>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import services from '@/helpers/services'

const helpOP = ref()
const variables = ref([]);

onMounted(async () => {
  variables.value = await services.getMediaDescriptionVars()
})
</script>

<style lang="scss" scoped>
ul {
  list-style: none;

  li {
    :first-child {
      font-size: 0.8rem;
      opacity: 0.90;
    }
  }
}

.help-icon {
  color: var(--guo-color-primary);
  cursor: pointer;
}

.help-overlay-panel {
  max-width: 200px;
}
</style>