<template>
  <slot></slot>
</template>

<script setup>
import { onMounted } from 'vue';
import { useThemeStore, colorSchemes } from '@/stores/theme'

const themeStore = useThemeStore()

onMounted(() => {
  const theme = themeStore.getTheme() || themeStore.getMediaPreference();
  themeStore.setTheme(theme);
  themeStore.themeSet = theme === colorSchemes.dark;
})
</script>

<style lang="scss" scoped></style>