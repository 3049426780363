<template>
  <span class="p-float-label">
    <InputMask :id="props.id" v-model="inputModel" @update:modelValue="handleChange" :disabled="props.disabled" mask="99"
      placeholder="00" style="max-width:80px" />
    <label :for="props.id">{{ props.label }}</label>
  </span>
</template>

<script setup>
import { ref, watch } from 'vue'
import InputMask from 'primevue/inputmask';

const emit = defineEmits(['change'])
const props = defineProps(['id', 'label', 'value', 'min', 'max', 'disabled'])

const inputModel = ref('00');

const validateMinMax = (newValue) => {
  // check if the new value is greater than the max prop
  inputModel.value = parseInt(newValue) >= parseInt(props.max) ? props.max : newValue
  // check if the new value is smaller than the min prop
  inputModel.value = parseInt(inputModel.value) <= parseInt(props.min) ? props.min : inputModel.value
}

const handleChange = (newValue) => {
  // si no ha terminado de escribir los 2 digitos, retorna
  const newValueLength = newValue.split(['_']).length
  if (newValueLength ===2) return;

  // asegurarse de que este entre los valores minimo y maximo
  validateMinMax(newValue)

  // emitir el evento de que cambio
  emit('change', inputModel.value)

}
watch(props, () => {
  // si se deshabilita, reestablece el valor a zero
  if (props.disabled) {
    inputModel.value = '00';
    handleChange(inputModel.value);
  }

  validateMinMax(inputModel.value)
})
</script>
