<template>
  <div class="card-body py-0 px-3">
    <Accordion class="accordion-review my-4" :activeIndex="0">
      <AccordionTab>
        <template #header>
          <i class="bi bi-diagram-3"></i>
          <span>Canal donde se crearán los eventos</span>
        </template>
        <div class="cursor-default">
          <div class="d-flex align-items-center justify-content-start gap-5">
            <div v-for="channel in store.channelsData" :key="channel.id" class="p-tabview position-relative">
              <div class="d-flex align-items-center stretched-link">
                <div class="thumb-tab d-flex align-items-center me-2">
                  <img :src="`${channel.logo}`" :alt="`Logo de ${channel.label}`" class="img-fluid" />
                </div>
                <div>{{ channel.label }}</div>
              </div>
            </div>
          </div>
        </div>
      </AccordionTab>
      <AccordionTab>
        <template #header>
          <i class="bi bi-ui-checks-grid"></i>
          <span>Tipo de evento a gestionar</span>
        </template>
        <div class="cursor-default">
          <div class="d-flex align-items-center gap-2 w-100">
            <div class="d-flex align-items-center">
              <img class="review-thumb-content" :src="imageSrc"
                :alt="`Representación gráfica para el tipo de contenido ${store.eventType?.name}`" />
            </div>
            <div class="d-flex align-items-center flex-column">
              Contenido: {{ defaultEventTypes[store.eventType]?.label }}
            </div>
          </div>
        </div>
      </AccordionTab>
      <AccordionTab>
        <template #header>
          <i class="bi bi-card-list"></i>
          <span>Se asignará contenido a:</span>
        </template>
        <div class="cursor-default">
          <div class="d-flex gap-5">
            <DataTable class="datatable-clients" :value="store.episode.clients" tableStyle="min-width: 100%"
              datakey="location.locationId" v-model:selection="selectedClient" selectionMode="single">
              <Column field="location.clientId" header="ID" dataType="numeric"></Column>
              <Column field="location.name" header="Nombre"></Column>
              <Column field="partner.partnerGroup.name" header="Grupo socio"></Column>
              <Column field="partner.name" header="Socio"></Column>
              <Column field="location.ubication.country.name" header="País">
                <template #body="{ data }">
                  <div class="d-flex align-items-center gap-2">
                    <flag :iso="data.location?.country?.toLowerCase()" class="flag-size-24px" />
                    <span>{{ countryNames[data.location?.country?.toLowerCase()] }}</span>
                  </div>
                </template>
              </Column>
            </DataTable>
            <ContentPreview :client="selectedClient" />
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useGlobalStore } from '@/stores'
import ContentPreview from '@/components/contentPreview/ContentPreview.vue'
import { countryNames, defaultEventTypes } from '@/helpers/helpers'

const store = useGlobalStore()
const imageSrc = require("@/assets/images/wire-post.svg")
const selectedClient = ref({})

</script>

<style lang="scss" scoped>
.accordion-review i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.review-thumb-content {
  width: 60px;
}

.datatable-clients {
  :deep(.p-datatable-tbody) {
    .p-selectable-row {

      &.p-highlight {
        background: var(--guo-color-primary) !important;
      }

      &:hover {
        background: var(--guo-color-primary-hover) !important;
      }
    }
  }
}
</style>