<template>
  <div class="card-body p-0">
    <TabHeader title="¿En qué canal quieres crear eventos?" />
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="wrap-channels">
        <ul id="list-channels" class="nav nav-pills nav-pills-custom align-items-center justify-content-center gap-3"
          role="tablist">
          <li class="nav-item position-relative" role="presentation" v-for="channel in channels" :key="channel.id"
            :class="{ 'selected': isSelected(channel.name) }">
            <a class="channel-select nav-link d-flex flex-column overflow-hidden py-3 stretched-link" role="tab"
              :class="{ 'active': isSelected(channel.name) }" @click="handleChannelSelected(channel.name)"
              :data-channel="channel?.name">
              <div class="nav-icon d-flex justify-content-center">
                <img :src="channel?.logo" :alt="`Logo de ${channel?.name}`" />
              </div>
              <span class="nav-text fw-bold fs-6 lh-1">{{ channel?.label }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useGlobalStore } from '@/stores/index'
import { defaultChannels } from '@/helpers/helpers'

const channels = ref(Object.values(defaultChannels));

const store = useGlobalStore();

const handleChannelSelected = (channelName) => {
  store.setChannels(channelName)
}

const isSelected = (channelName) => {
  return store.channels.has(channelName)
}

</script>