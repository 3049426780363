import Keycloak from "keycloak-js";

const keycloackVars = {
  AUTH_URL: `${window.config.KAU}/auth`,
  REALM: window.config.KR,
  CLIENT_ID: window.config.KCID,
};

const keycloak = new Keycloak({
  url: keycloackVars.AUTH_URL,
  realm: keycloackVars.REALM,
  clientId: keycloackVars.CLIENT_ID,
});

keycloak.authServerUrl = keycloackVars.AUTH_URL;
keycloak.realm = keycloackVars.REALM;

// funcion custom para obtener la informacion 
// necesaria sobre el usuario y su sesion actual
// con proposito de loguearla.
keycloak.getUserSession = function () {
  return {
    user: keycloak.tokenParsed.preferred_username,
    email: keycloak.tokenParsed.email,
    session_state: keycloak.sessionId,
  };
};

export default keycloak;
