<template>
  <div>
    <h6>Contenido</h6>
    <p>{{ content }}</p>
  </div>
</template>

<script setup>
import { useGlobalStore } from '@/stores'
import { computed } from 'vue';

const props = defineProps({
  client: Object
})

const store = useGlobalStore()

const replacePlaceholders = (str) => {
  const regex = /\/(\w+)/g;
  const replacedString = str.replace(regex, (match, key) => {
    switch (key) {
      case "nombre":
        return props.client?.name || match;
      case "ubicacion":
        return props.client?.location?.address || match;
      case "tipologia":
        return props.client?.tipologia || match;
      default:
        return match
    }
  });

  return replacedString;
}


const content = computed(() => {
  if (!store.episode.mediaDescription) return ""
  return replacePlaceholders(store.episode.mediaDescription)
})
</script>

<style lang="scss" scoped></style>