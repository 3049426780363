<template>
  <div class="wrap-profile d-flex flex-column pb-2">
    <div class="rs-hero-image">
      <div class="hero-image__bg-image">
        <figure class="company-hero-image-container">
          <div
            :style="`background-image: url('${this.bgHero}');`"
            class="hero-background-image"
          ></div>
        </figure>
      </div>
    </div>
    <div class="position-relative">
      <div class="px-box">
        <div class="org-top-card">
          <div class="org-top-card-content">
            <img
              src="@/assets/images/linkedin/logo-for-linkedin.jpg"
              alt="Logotipo de Pizza Natura"
              class="org-image-logo"
            />
          </div>
          <div class="d-block mt-2">
            <div class="org-top-card-summary-title" title="Pizza Natura">
              Pizza Natura
            </div>
            <p class="org-top-card-summary-tagline">LO NATURAL SABE MEJOR</p>
            <div class="org-top-card-summary-info-list">
              <div class="org-top-card-summary-info-list__info-item">
                Hostelería
              </div>
              <div class="d-inline">
                <div class="org-top-card-summary-info-list__info-item">
                  Madrid, Comunidad de Madrid
                </div>
                <div class="org-top-card-summary-info-list__info-item">
                  126 seguidores
                </div>
                <div class="org-top-card-summary-info-list__info-item">
                  8 empleados
                </div>
              </div>
            </div>
            <div class="org-top-card-actions">
              <div class="wrap-actions">
                <a
                  href="javascript:void(0);"
                  class="org-company-button follow"
                  target="_blank"
                  >Ver perfil</a
                >
                <a
                  href="javascript:void(0);"
                  class="org-company-button edit"
                  target="_blank"
                  >Editar perfil</a
                >
              </div>
            </div>
          </div>
          <div class="wrap-info mt-3">
            <dl>
              <dt>Sitio web</dt>
              <dd>http://www.pizzanatura.es</dd>
              <dt>Teléfono</dt>
              <dd>919223375El número de teléfono es 919223375</dd>
              <dt>Sector</dt>
              <dd>Hostelería</dd>
              <dt>Tamaño de la empresa</dt>
              <dd>11-50 empleados</dd>
              <dd>8 en LinkedIn</dd>
              <dt>Sede</dt>
              <dd>Madrid, Comunidad de Madrid</dd>
              <dt>Fundación</dt>
              <dd>2020</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import { ref } from 'vue';

export default {
  data() {
    return {
      bgHero: require("@/assets/images/linkedin/bg-for-linkedin.jpg"),
    };
  },
};
</script>
<style scoped></style>
