<template>
  <div class="preview d-flex flex-column align-items-center rounded border">
    <div class="py-3">Preview para GBP</div>
    <div class="wrap-img">
      <img :src="thumbcontent" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["thumbcontent"],
};
</script>
<style></style>
